import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Talks`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#neverrestingrestfulapibestpracticesusingaspnetwebapi"
        }}>{`Never RESTing - RESTful API Best Practices using ASP.NET Web API
`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#anopinionatedmaintainablerestapiarchitectureforaspnetcore"
        }}>{`An Opinionated, Maintainable REST API Architecture for ASP.NET Core
`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#versioningandmaintainingyourrestapiinaspnetcore"
        }}>{`Versioning and Maintaining Your REST API Using ASP.NET Core`}</a></li>
    </ul>
    <hr></hr>
    <h3>{`Never RESTing - RESTful API Best Practices using ASP.NET Web API`}</h3>
    <h4>{`Overview`}</h4>
    <p>{`Designing and building RESTful APIs isn’t easy. On its surface, it may seem simple – after all, developers are only marshaling JSON back and forth over HTTP, right? Believe it or not, that’s only a small part of the equation. There are many things to keep in mind while building the systems that act as the key to your system.`}</p>
    <p>{`In this session, we’ll delve into several best practices to keep in mind when designing your RESTful API. Attendees will learn about authentication, versioning, controller/model design, testability, documentation and change management. This session will also explore the do’s and don’t’s of RESTful API management so that you make sure your APIs are simple, consistent, and easy-to-use.`}</p>
    <p>{`Examples will be done using ASP.NET Web API and C#. However, this session will benefit anyone who is or might be working on a RESTful API.`}</p>
    <p><em parentName="p">{`Based on `}<a parentName="em" {...{
          "href": "https://medium.com/@schneidenbach/restful-api-best-practices-and-common-pitfalls-7a83ba3763b5#.cblypia39"
        }}>{`RESTful API Best Practices and Common Pitfalls`}</a></em></p>
    <h4>{`Video`}</h4>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/x0yNKU-tz1Y" frameBorder="0" allowFullScreen></iframe>
    <p>Recorded by <a href="https://vimeo.com/ndcconferences">NDC Conferences</a>.</p>
    <h4>{`Links`}</h4>
    <span style={{
      "fontSize": "18pt"
    }}>
    <a href="https://yourdais.com/app/dais/gelZIkd2JCZDjI9U99xW">Download Slides</a>
    </span>
    <h4>{`Further Reading`}</h4>
    <p><a parentName="p" {...{
        "href": "https://github.com/Microsoft/api-guidelines/blob/master/Guidelines.md"
      }}>{`Microsoft REST API Guidelines`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://github.com/WhiteHouse/api-standards"
      }}>{`White House’s guide on RESTful APIs`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://schneids.net/paging-in-asp-net-web-api/"
      }}>{`Paging in ASP.NET Web API`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://github.com/OData/WebApi"
      }}>{`OData Web API Github`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://github.com/interagent/http-api-design"
      }}>{`HTTP API Design Guide`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://github.com/Azure/azure-content/blob/master/articles/best-practices-api-implementation.md"
      }}>{`Azure team’s API Implementation guidance`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://app.pluralsight.com/library/courses/building-securing-restful-api-aspdotnet/table-of-contents"
      }}>{`Building and Securing a RESTful API for Multiple Clients in ASP.NET`}</a>{` – Pluralsight course`}</p>
    <hr></hr>
    <h3>{`An Opinionated, Maintainable REST API Architecture for ASP.NET Core`}</h3>
    <h4>{`Overview`}</h4>
    <p>{`Writing a brand-new RESTful API using ASP.NET has never been easier, or so you’ve been led to believe. Create a project, write a few models, and scaffold – instant API for your website! While convenient, this leads to a lot of pain as your API scales. Testability and maintainability become more and more difficult as your codebase continues to expand. Eventually, you’re left with a huge mess of code and zero separation of concerns.`}</p>
    <p>{`In this session, Spencer will provide a highly-opinionated and easy-to-learn pattern for creating scalable, maintainable APIs powered by ASP.NET Core. Attendees will learn about dependency injection, validating requests, executing requests via services, error handling, and versioning strategies to make sure your API lasts for the long haul.`}</p>
    <h4>{`Video`}</h4>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/CH9VEeV-zok" frameBorder="0" allowFullScreen></iframe>
    <p>Recorded by <a href="https://vimeo.com/ndcconferences">NDC Conferences</a>.</p>
    <h4>{`Links`}</h4>
    <span style={{
      "fontSize": "18pt"
    }}>
    <a href="https://github.com/schneidenbach/OpinionatedMaintainableRestAPIsWithAspNetCore">Slides</a>
    </span>
    <p><a parentName="p" {...{
        "href": "https://github.com/schneidenbach/RecessOpinionatedApiInAspNetCore"
      }}>{`Recess - an example API project for ASP.NET Core`}</a></p>
    <h4>{`Further Reading`}</h4>
    <p><a parentName="p" {...{
        "href": "https://lostechies.com/jimmybogard/2014/09/09/tackling-cross-cutting-concerns-with-a-mediator-pipeline/"
      }}>{`Tackling cross-cutting concerns (good example of using validation with services)`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "http://docs.autofac.org/en/latest/integration/aspnetcore.html"
      }}>{`ASP.NET Core Dependency Injection with AutoFac`}</a>{`  `}</p>
    <hr></hr>
    <h3>{`Versioning and Maintaining Your REST API in ASP.NET Core`}</h3>
    <h4>{`Overview`}</h4>
    <p>{`When tasked with making a REST API for your system, you'll inevitably have to think about versioning. One of the most difficult parts of API maintenance, versioning is an essential conversation to have about the future of your API, even before you hit File -> New Project. Do you version in the URL (v1/api/whatever)? Do you use header versioning? Should I update my production API in place?`}</p>
    <p>{`In this session, Spencer will provide several strategies and real world examples of API versioning done right... and wrong. We'll discuss versioning strategies such as header versioning and URL versioning, as well as best practices on how to intelligently version your REST API without tearing your hair out... or making your consumers tear their hair out.`}</p>
    <h4>{`Links`}</h4>
    <a href="https://github.com/schneidenbach/VersioningAndMaintainingYourRestApiInAspNetCore">
    Slides
    </a>
    <p><a parentName="p" {...{
        "href": "https://github.com/Microsoft/aspnet-api-versioning/wiki"
      }}>{`ASP.NET Versioning Docs`}</a>{`  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      